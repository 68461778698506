(function() {
    angular.module('EntrakV5').controller('toAppController', toAppController);

    function toAppController($scope) {
        console.log('toAppController');

        // Scripts may close only the windows that were opened by them.        
        // setTimeout(function(){
        // 	window.close();
        // }, 500);
        window.location.replace("tep://");



        $scope.$on('$destroy', function() {
            console.log("toAppController destroy");
        });
    }
})();
